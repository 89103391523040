dp-month-calendar.dp-material {
  button.dp-current-month {
    border: 1px solid $brand;
  }
  button.dp-selected {
    background: $brand;
  }
}

dp-date-picker.dp-material {
  input.dp-picker-input {
    height: 20px;
    border: none;
    border-bottom: 1px solid;
  }
}

.month-picker {
  position: relative;
  fa-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
