@for $i from 0 through 40 {
  .padding-#{$i*2} {
    padding: #{$i * 2}px !important;
  }

  .padding-t-#{$i*2} {
    padding-top: #{$i * 2}px !important;
  }

  .padding-b-#{$i*2} {
    padding-bottom: #{$i * 2}px !important;
  }

  .padding-tb-#{$i*2} {
    padding-top: #{$i * 2}px !important;
    padding-bottom: #{$i * 2}px !important;
  }

  .padding-l-#{$i*2} {
    padding-left: #{$i * 2}px !important;
  }

  .padding-r-#{$i*2} {
    padding-right: #{$i * 2}px !important;
  }

  .padding-lr-#{$i*2} {
    padding-left: #{$i * 2}px !important;
    padding-right: #{$i * 2}px !important;
  }
}

@for $i from 1 through 12 {
  .tree-node-pl-#{$i} {
    padding-left: #{18 * ($i - 1)}px;
  }
}
