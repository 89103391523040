.text-decoration-underline {
  text-decoration-line: underline;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.font-size-12 {
  font-size: 12px;
}

.align-center {
  text-align: center;
}
