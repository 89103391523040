@import 'variables';
@import 'typo';

.form-field-error {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $color-red-orange;
  margin-top: 4px;
}

dc-form-field-wrapper {
  display: block;
  width: 100%;

  .dc-form-field {
    label {
      @include font-regular();
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    dc-form-input {
      display: flex;
      width: 100%;
    }

    .input-field-container {
      width: 100%;
      height: auto;
      position: relative;
      display: flex;

      &.field-limited {
        input {
          padding-right: 30px;
        }
      }

      &.field-cleared {
        input {
          padding-right: 20px;
        }
      }

      .field-length {
        @include font-regular();
        font-size: 12px;
        line-height: 14px;
        color: $gray-light;
        position: absolute;
        display: flex;
        align-items: flex-end;
        bottom: 0;
        right: 0;
      }

      .field-clear {
        @include font-regular();
        font-size: 12px;
        line-height: 14px;
        color: $gray-light;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        height: 100%;

        &.active {
          cursor: pointer;
          color: $color-brand-light;
        }
      }
    }

    .textarea-field-container {
      display: flex;
      width: 100%;

      &.field-limited {
        position: relative;

        .field-length {
          @include font-regular();
          font-size: 12px;
          line-height: 14px;
          color: $gray-light;
          position: absolute;
          display: flex;
          align-items: flex-end;
          bottom: 3px;
          right: 5px;
        }
      }
    }

    &.bootstrap {
      &.dc-form-field {
        width: 100%;

        textarea {
          line-height: 1.25;
        }

        textarea::placeholder {
          line-height: 1.25;
          font-size: inherit;
        }
      }

      input {
        font-family: Roboto;
        border: 1px solid $gray-light;
        border-radius: 5px;
        padding: 8px 10px 7px 10px;
        height: 34px;
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        background-color: $white;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &.ng-touched {
          &.ng-invalid {
            background: $color-red-orange-03;
            border: 1px solid $color-red-orange;
          }
        }

        &[disabled] {
          opacity: 0.5;
        }

        &::placeholder {
          @include font-regular();
          font-size: 14px;
          line-height: 150%;
          color: $gray-light;
        }

        &:focus {
          box-shadow: none;
          outline: 0;
        }
      }

      textarea {
        border: 1px solid $gray-light;
        box-sizing: border-box;
        border-radius: 5px;
        @include font-regular();
        padding: 10px 10px 15px 10px;
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        background-color: $white;
        font-size: 16px;
        resize: none;
        min-height: 60px;

        &.ng-touched {
          &.ng-invalid {
            background: $color-red-orange-03;
            border: 1px solid $color-red-orange;
          }
        }

        &::placeholder {
          @include font-regular();
          font-size: 14px;
          line-height: 150%;
          color: $gray-light;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    &.material {
      input {
        font-family: Roboto;
        padding: 0 0 8px;
        border: none;
        border-bottom-color: $gray-light;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-radius: 0;
        display: flex;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        outline: 0;
        width: 100%;

        &:focus {
          box-shadow: none;
          outline: 0;
        }

        &::placeholder {
          @include font-regular();
          font-size: 14px;
          line-height: 16px;
          color: $gray-light;
        }
      }
    }

    &.inline-field {
      display: flex;
      align-items: center;
      width: 100%;

      label {
        min-width: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        margin: 0;
      }

      .form-field-error {
        margin-top: 0;
      }
    }
  }
}

.dc-radio-input {
  input[type='radio'] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      margin-bottom: 10px;

      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 1px solid darken($white, 25%);
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        position: relative;
        top: -1px;
        margin-right: 13px;
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color-brand-light;
        }
      }
    }

    &:disabled {
      + .radio-label {
        opacity: 0.5;

        &:before {
          opacity: 0.5;
          box-shadow: inset 0 0 0 2px $white;
          border-color: $color-brand-light;
          background: $white;
        }
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $color-brand-light;
          box-shadow: inset 0 0 0 2px $white;
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.switch-wrapper {
  display: flex;
  align-items: center;
}

.switch {
  margin: 0 2px;
  width: 24px;
  height: 16px;
  padding: 4px;
  background: $color-brand-light;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;

  input {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  &.active {
    justify-content: flex-start;
  }

  &.without-labels {
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
}

.switch-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: $white;
}
