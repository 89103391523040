@import "../_variables.scss"

$color2: #ecba00 // Жёлтый
$color3: #6cccd4 // Голубой

$dc-green: #20ff53
$dc-gray: #6b6b6b
$dc-gray-border: lighten($dc-gray, 30%)
$dc-red: #ff0000
$dc-yellow: #fff803
$dc-cadetblue: #395c67

$color6: #1c4b59
$color7: #86b7c0

$color8: #323232
$color9: #6D99A2
$color10: #496F7A
$color11: #AEC4C9
$faq-modal-control: #C1C1C1

$kt-gray: #CBCBCB

=color1font
  color: $white
.color1font
  +color1font
