@import '../variables';
@import '../parts/_color';

$nomenclatures-width: 525px;
$nomenclatures-compact-width: 271px;
$select-width: 25px;

.lot-group {
  position: relative;
  display: grid;
  grid-template-columns: $nomenclatures-width auto;
  height: auto;
  max-height: 475px;
  min-height: 151px;
  width: fit-content;
  max-width: 100%;
  overflow: auto;

  &.compact {
    grid-template-columns: $nomenclatures-compact-width auto;

    &.readonly {
      grid-template-columns: $nomenclatures-compact-width - $select-width auto;
    }
  }

  &.readonly {
    grid-template-columns: $nomenclatures-width - $select-width auto;
  }

  dc-form-field-wrapper,
  dc-form-field-wrapper .dc-form-field,
  dc-form-field-wrapper .dc-form-field dc-form-input {
    height: 100%;
  }

  dc-form-field-wrapper .dc-form-field.bootstrap textarea {
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    font-size: 12px;
    border-radius: 0;
    min-height: 42px;
    padding: 5px 10px 15px 10px;

    &.ng-touched.ng-invalid {
      border: 1px solid transparent;

      &:focus {
        background: #e9f0f1;
        border: 1px solid $color-brand-light;
      }
    }

    &:focus {
      background: #e9f0f1;
      border: 1px solid $color-brand-light;
    }
  }

  dc-form-field-wrapper .dc-form-field .textarea-field-container.field-limited .field-length {
    font-size: 10px;
    line-height: 15px;
  }

  ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    min-height: 26px;
    line-height: 26px;

    &.ng-option-selected {
      color: $color-dark-charcoal;
    }

    &:first-child {
      display: none;
    }
  }
}

.readonly .lot-nomenclatures .disabled {
  opacity: 1;
}

.lot-nomenclatures {
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  border-right: 2px solid $gray-light;
  width: $nomenclatures-width;
  height: fit-content;
  min-height: 100%;

  .readonly & {
    width: $nomenclatures-width - $select-width;
  }

  .compact & {
    width: $nomenclatures-compact-width;
  }

  .compact.readonly & {
    width: $nomenclatures-compact-width - $select-width;
  }

  &-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    display: grid;
    grid-template-columns: $select-width 232px 12px auto;
    color: $color-brand-light;

    b {
      font-weight: 500;
    }

    .readonly & {
      grid-template-columns: 232px 12px auto;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: $select-width 244px 100px 65px 89px;

    &--sticky {
      position: sticky;
      top: 24px;
      z-index: 1;
      background: white;
    }

    & > a {
      position: relative;
    }

    b {
      font-weight: 500;
    }

    .readonly & {
      grid-template-columns: 244px 100px 65px 89px;
    }

    &.hide-cols {
      grid-template-columns: $select-width 244px;

      .readonly & {
        grid-template-columns: 244px;
      }
    }
  }

  &-th {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $color-snow;
    padding: 0 10px;
    border-top: 1px solid $gray-light;

    &--without-paddings {
      padding: 0;
    }

    .lot-nomenclatures-header & {
      height: 24px;

      &:nth-child(2) {
        border-left: 1px solid $gray-light;
        border-top-left-radius: 4px;
      }
    }

    .readonly .lot-nomenclatures-header & {
      &:nth-child(2) {
        border-left: 0;
        border-top-left-radius: 0;
      }

      &:first-child {
        border-left: 1px solid $gray-light;
        border-top-left-radius: 4px;
      }
    }

    .lot-nomenclatures-row & {
      height: 66px;
      border-left: 1px solid $gray-light;
      border-bottom: 1px solid $gray-light;
    }
  }

  &-td {
    display: flex;
    min-height: 42px;
    height: 61px;
    font-size: 12px;
    border-bottom: 1px solid $gray-light;
    border-left: 1px solid $gray-light;

    &.collapsed {
      color: $color-brand-light;
      height: 26px;
      min-height: 26px;
      padding: 0 10px;
      border-top: 0;
      background-color: $color-snow;
      font-size: 14px;
      font-weight: 500;
      gap: 10px;
      align-items: center;
    }
  }
}

.weight-cell {
  position: relative;

  .kt-input {
    padding: 10px;
    padding-bottom: calc(100% - 30px);
    text-align: left;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;

    &:focus {
      background: #e9f0f1;
      border: 1px solid $color-brand-light;
    }
  }
}

.th-search {
  position: relative;

  .search-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cell-search {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-right: 5px;

  .field-length {
    position: absolute;
    bottom: 3px;
    right: 5px;
    display: flex;
    align-items: flex-end;
    font-size: 10px;
    line-height: 15px;
    color: $gray-light;
  }
}

.lot-criteria-items {
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  min-height: 100%;
  z-index: 1;
}

.line {
  position: relative;
  width: calc(100% - 5px);
  margin-left: 5px;
  height: 2px;
  border-radius: 1px;
  background-color: $color-brand-light;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2px;
    height: 5px;
    background-color: $color-brand-light;
    border-radius: 1px;
  }
}

.bg-snow {
  background-color: $color-snow;
}

.full-row {
  grid-column-start: 2;
  grid-column-end: 11;

  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  padding-top: 10px;
  padding-bottom: 20px;

  column-gap: 30px;
  user-select: none;
}

.kt-sbets-item-frh {
  font-weight: 500;
}

.kt-sbets-open-space {
  grid-column-start: 1;
  grid-column-end: 7;
  height: 0;
  overflow: hidden;
}

.kt-sbets-propnames {
  display: flex;
  padding-top: 10px;
  grid-template-columns: 410px 5fr;
  align-content: baseline;
}

.kt-sbets-prop-left {
  display: grid;
  grid-template-columns: 112px 30px auto auto;
  align-content: baseline;
  border-right: 1px solid $kt-gray;
  position: relative;
  margin-right: 16px;
  width: 500px;
  grid-column-gap: 10px;
  padding-right: 5px;
  &.no-trade {
    width: 390px;
    grid-template-columns: 45px auto auto;
  }
}
.kt-sbets-prop-l-br {
  grid-column-start: 1;
  grid-column-end: 5;
  &.no-trade {
    grid-column-end: 4;
    grid-template-columns: auto auto auto;
  }
}

.kt-sbets-prop-right {
  overflow-x: scroll;
  width: 0;
  display: flex;
  padding-bottom: 20px;
  &.width-expanded {
    width: calc(100vw - 950px);
    &.no-trade {
      width: calc(100vw - 850px);
    }
  }
}

.kt-sbets-prop-right-inset {
  display: grid;
  align-content: baseline;
  grid-template-columns: auto auto 35px 120px;
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid $kt-gray;
  grid-column-gap: 20px;
  position: relative;
  overflow: hidden;
  width: calc(100% - 240px);
  &.no-trade {
    width: auto;
    grid-template-columns: auto 30px auto;
  }
}

app-criteria-view {
  position: relative;
  .criteria-expander {
    right: 23px;
  }
  &:last-of-type {
    .kt-sbets-prop-right-inset {
      &.no-trade {
        &:last-of-type {
          border: none;
        }
      }
    }
  }
}

.kt-sbets-prop-right-h1 {
  grid-column-start: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.kt-bets-trades {
    grid-column-end: 4;
  }
}

.criteria-expander {
  position: absolute;
  right: -6px;
  top: 50%;
  margin-top: -11px;
  background: $white;
  margin-right: 0 !important;
  transform: rotate(180deg);
  color: $color-brand-light;
}

.trigger-text {
  cursor: pointer;
  &.readonly {
    cursor: default;
  }
  &.active {
    color: black;
  }
}

.kt-sbets {
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 30px;
  position: relative;
}

.kt-sbets-inner {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 70px 35px 35px 36px 100px 315px repeat(3, minmax(125px, 222px)) 25px;
  padding-top: 3px;
  &.company-rep-gripped {
    grid-template-columns: 70px 35px 35px 36px 100px 130px repeat(3, minmax(125px, 222px)) 25px;
  }
}

.kt-sbets-item-all {
  grid-column-start: 5;
  grid-column-end: 8;
}

.new-line {
  grid-column-start: 1;
}

.kt-sbets-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.expandable {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.criteria-comment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lots-select {
  &.ng-select {
    width: 80%;
    max-width: 135px;
    display: inline-flex;
    padding-bottom: 0;
    margin-right: 10px;
    input {
      padding: 0;
    }
    .ng-select-container {
      min-height: 20px;
      .ng-clear-wrapper {
        bottom: 0;
      }
      .ng-arrow-wrapper {
        bottom: 3px;
      }
      .ng-value-container {
        padding: 0;
        border-top: 0 solid transparent;
      }
    }
  }
  &.ng-select.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 0;
        }
      }
    }
  }
  .ng-dropdown-panel {
    min-width: 300px;
    top: 22px;
    bottom: auto;
  }
}

.service-menu {
  &-item {
    &.disabled {
      opacity: 0.5;
    }
  }
}

.criteria-range {
  display: flex;
}

.criteria-range-value {
  width: 60px;
  margin: 0 2px;
  @extend .text-overflow;
  display: block;
}

.criteria-value {
  @extend .text-overflow;
  text-align: left;
  width: 80px;
}

.criteria-date-value {
  width: 80px;
  text-align: left;
}

.general-weight-label {
  font-weight: 500;
  font-size: 16px;
}

.kt-small-input {
  text-align: center;
  border-bottom: #dbdbdb 1px solid;
}

.service-menu {
  top: 7px;
  left: 7px;
}

dc-form-field-wrapper .dc-form-field.bootstrap textarea.ng-touched.ng-invalid {
  background: rgba($color-red-orange, 0.15);
  border: 1px solid $gray-light;
}
