@import 'constants';

.cm-projects {
  height: calc(100vh - #{$header-height} - #{$tab-height} - 235px);

  h2 {
    margin-right: 30px;
  }
}

.projects-agreement-count-label {
  font-size: 12px;
  color: $color-brand-light;
  span {
    color: $color-dark-charcoal;
  }
}
