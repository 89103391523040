/* Статусы */

@import '../parts/color';

.status-green {
  color: $color-mantis !important;
}
.status-red {
  color: $color-red-orange !important;
}

.status-yellow {
  color: $color-lemon !important;
}

.bg-green {
  background: $color-mantis;
}

.bg-red {
  background: $color-red-orange;
}

.bg-yellow {
  background: $color-lemon;
}

.status-blue {
  color: #546de5 !important;
}

/* цвета */

.color-grey {
  color: #cacaca;
}

.color-white {
  color: $white;
}

.color-l-blue {
  color: $white;
}

.color-brand {
  color: $brand;
}

.general-weight-value {
  color: $black;
  width: 37px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: rgba($color-brand-light, 0.15);
}

.invalid-bg {
  background: rgba($color-red-orange, 0.15);
}

.blue-links {
  color: $brand;
  cursor: pointer;

  a,
  a:not([href]):not([tabindex]) {
    color: $brand;
    margin-right: 7px;
    &:hover {
      color: $brand;
    }
  }
  fa-icon {
    color: $brand;
  }
}

.blue-links-button {
  color: $color-brand-middle;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.grey-link {
  color: #bbb !important;
}

.ul-padding {
  padding-top: 10px;
}

.small-equal {
  font-size: 10px;
  color: #707070;
  margin-right: 5px;
}

.no-pointer {
  cursor: default !important;
}
