@import 'variables';
@import 'typo';

// base styles

.modal-backdrop {
  &.show {
    opacity: 0.25 !important;
  }
}

.dc-modal.modal-window.custom-modal-transparent-no-shadow {
  .modal-content {
    box-shadow: none;
    background-color: transparent;
  }
}

.dc-modal {
  // .modal-dialog {
  //   max-width: 550px;
  // }
  .modal-content {
    padding: 30px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

    .multiple-search-text-all {
      margin-bottom: 10px;
    }

    .multiple-search-text-list,
    .simple-checkbox-list {
      overflow-y: auto;
      max-height: 250px;
    }

    .move-pin__close-button {
      cursor: pointer;
      position: absolute;
      z-index: 100001;
      top: -15px;
      right: -15px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      box-shadow: 0 5px 15px rgb(28 75 89 / 20%);
      text-align: center;
      background: white;
      font-size: 20px;

      &.disabled_icon {
        pointer-events: none;
        opacity: 0.5;
      }

      &::before,
      &::after {
        position: absolute;
        left: 15px;
        top: 8px;
        content: ' ';
        height: 14px;
        width: 1px;
        background-color: #333;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .modal-header {
    margin-bottom: 20px;
    padding: 0;
    border: none;
    justify-content: flex-start;
  }

  .modal-body {
    padding: 0;
    margin-bottom: 30px;
  }

  .dc-footer-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 0;
    justify-content: space-between;

    > * {
      margin: 0;
    }
  }

  .modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $color-dark-charcoal;
  }
}

.status-log-modal {
  .modal-content {
    width: 400px;
    height: 310px;

    .modal-header {
      align-items: center;
      justify-content: center;

      .modal-title {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

.export-file-manager-modal {
  .modal-header {
    margin-bottom: 20px;
    padding: 0;
    border: none;
    justify-content: center;
  }

  .modal-content {
    .file-manager {
      &__min-height {
        .file-manager__tree-modal {
          max-height: calc(73vh - 280px);
        }

        .file-manager__area {
          max-height: calc(73vh - 280px);
        }
      }
    }

    .file-manager__tree-modal {
      max-height: calc(73vh - 220px);
    }

    .file-manager__area {
      max-height: calc(73vh - 220px);
    }
  }
}

// custom - need refactoring !!!
.grid-options-modal {
  min-width: 700px;
}

.modal-dialog-share {
  .modal-dialog {
    min-width: 80%;

    .modal-body {
      max-height: none;
    }
  }
}

.user-card-modal {
  .modal-dialog {
    max-width: 450px;
  }
}

.dc-modal-user-card {
  .modal-dialog {
    max-width: 350px;
    margin-top: 230px;
  }
}

.employee-competence-modal {
  .modal-dialog {
    max-width: 700px;
    max-height: 450px;
    margin-top: 230px;
  }
}

.filter-template-modal {
  min-width: 960px;
}

.project-changes-modal {
  .modal-dialog {
    max-width: 790px;
  }

  &.project-changes-modal-sm {
    .modal-dialog {
      max-width: 500px;

      .modal-content {
        padding: 0;
      }
    }
  }
}

.creating-modal-bg {
  opacity: 0.25 !important;
}

.faq-modal {
  color: $color-dark-charcoal;

  .modal-content {
    .modal-header {
      justify-content: center;
    }
  }

  .modal-body {
    max-height: 650px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .dc-modal-x-button {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

    app-svg-modal-close {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $color-brand-dark;
      }
    }
  }

  &.permission-modal {
    .modal-dialog {
      width: 700px;
    }
  }

  &.faq-status-modal {
    .modal-dialog {
      width: 390px;
    }
  }

  &.faq-hint-modal {
    .modal-dialog {
      width: 600px;
    }
  }
}

.fileman-modal {
  .modal-header {
    &.center {
      justify-content: center;
    }
  }

  .modal-dialog {
    max-width: 500px;
  }

  .modal-title {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: $color-dark-charcoal;
    @include font-medium();
  }

  .modal-body {
    font-size: 16px;
    font-weight: 400;

    .input-field-container {
      flex-direction: column;

      input {
        &.ng-touched.ng-invalid {
          background-color: inherit;
        }
      }
    }

    .dc-form-field {
      label {
        font-size: 16px;
        font-weight: 400;
        color: $color-dark-charcoal;
      }
    }
  }

  .dc-footer-buttons {
    button {
      margin-right: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 80px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.fileman-file-open {
  .modal-dialog {
    max-width: 670px;
  }
}

.modal.share-modal {
  top: 117px;
  left: 10px;
}

.share-modal {
  .modal-body {
    margin-bottom: 0;
  }

  .modal-dialog {
    max-width: 820px;
  }

  .modal-header {
    flex-direction: column;
    align-items: center;
  }

  .share-modal-search {
    position: relative;
  }

  .share-modal-header {
    display: flex;
    flex-direction: row;
    position: relative;

    .btn-link {
      color: $color-brand-light;
    }
  }

  .modal-footer {
    justify-content: center;
  }
}

.tariff-modal {
  .modal-dialog {
    max-width: 950px;
    margin-top: 100px;
    .modal-header {
      border-bottom: none;
    }

    // переопределения стилей ui kit временно отменено
    // .title {
    //   * {
    //     font-size: 16px;
    //     line-height: 18px;
    //     color: $color-dark-charcoal;
    //   }
    // }
  }
}

.tariff-sales-modal {
  .modal-dialog {
    max-width: 950px;
    margin-top: 200px;
    .modal-header {
      border-bottom: none;
    }
  }
}

.monthpicker-modal {
  .modal-dialog {
    margin-top: 300px;
    width: 390px;
  }
}

.auto-tariff-modal {
  .modal-dialog {
    margin-top: 200px;
    height: 500px;
    width: 450px;
    .modal-content {
      height: 500px;
    }
  }
}

.month-value-editor-modal {
  .modal-dialog {
    margin-top: 150px;
    width: 405px;
  }
}

.month-value-payment-editor-modal {
  .modal-dialog {
    margin-top: 150px;
    width: 435px;
  }
}

.tariffs-filter-modal-450 {
  .modal-dialog {
    max-width: 450px;
    margin-top: 13rem;
  }
}

.filter-modal-340 {
  .modal-dialog {
    max-width: 340px;
    margin-top: 13rem;
  }
}

.tariffs-filter-modal-400 {
  .modal-dialog {
    max-width: 400px;
    margin-top: 13rem;
  }
}

.add-modal {
  text-align: center;

  .modal-dialog {
    width: 600px;
    height: 400px;

    .modal-content {
      width: inherit;
      height: inherit;
    }
  }
}

.upload-transactions-mode {
  text-align: center;

  .modal-dialog {
    width: 700px;
    height: 600px;
    max-width: 700px;

    .modal-content {
      width: inherit;
    }
  }
}

.comments-modal {
  text-align: center;

  .modal-dialog {
    width: 600px;
    height: 500px;

    .modal-content {
      width: inherit;
      height: inherit;
    }
  }
}

.edit-group-modal {
  .modal-dialog {
    max-width: fit-content;
  }
}

.manual-mode {
  modal-dialog {
    min-width: 1500px;
    width: max-content;
    min-height: 800px;
  }
}

app-rich-modal.chat__collapsed .rich-modal {
  height: fit-content !important;
  width: fit-content !important;
  padding: 0 10px;
  flex-direction: row;
  align-items: center;
  min-height: 42px;

  .rich-modal__move {
    position: static;
  }
}

.contacts-filter-modal {
  .modal-dialog {
    max-width: 405px;
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;

    .modal-content {
      min-height: 310px;
    }
    .list-unstyled {
      min-height: 150px;
    }
  }
}

.contacts-only-filter-modal {
  .modal-content {
    min-height: 600px;
  }

  .modal-dialog {
    max-width: fit-content;
  }
}

.modal-content:has(app-spinner) {
  background-color: transparent;
  border: none;
  text-align: center;
}

.faq-create-question-modal > .modal-dialog {
  max-width: 900px;
}

.faq-module-sort-modal > .modal-dialog {
  max-width: 700px;
}
