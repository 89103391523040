.dc-competence-filter-tree-item {
  font-size: 12px;
  height: auto;
  margin-bottom: 10px;
  .switch-wrapper {
    padding-left: 16px;
  }
  .filter-specific {
    padding-top: 5px;
  }
}
