$filters-counter-size: 12px;

.competence-map-user-categories {
  min-width: 180px;
}

.competence-map-user-categories-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 26px;
}

.competence-map-filter-category {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  app-svg-icon {
    color: $color-brand-light;
  }
  &__title {
    font-size: 14px;
    margin-right: 36px;
    position: relative;
  }

  &__counter {
    position: absolute;
    top: -6px;
    right: -12px;
    // width: $filters-counter-size;
    height: $filters-counter-size;
    min-width: $filters-counter-size;
    min-height: $filters-counter-size;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #aec4c9;
    font-size: 8px;
    line-height: 100%;
    padding: 0 4px;
  }

  &__add-icon {
    cursor: pointer;
  }
}
