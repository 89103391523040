.dc-toolbar-btn {
  width: 45px;
  height: 45px;
  background: $brand;
  font-size: 1.35rem;
  text-align: center;
  color: $white;
  border: 0;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(109, 153, 162, 0.5);
  }

  &.btn-pill {
    border-radius: 0.3125rem 0.3125rem 0 0;
    width: 2.8125rem;
    color: $brand;
    border-top: 0.15rem solid $brand;
    border-left: 0.15rem solid $brand;
    border-right: 0.15rem solid $brand;
    background-color: $white;

    > .dc-toolbar-btn-title {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      padding: 0 0.9375rem;
      //display: none;
    }

    &.active {
      width: auto;
      background: $brand;
      color: $white;

      > .dc-toolbar-btn-title {
        margin-left: 0.25rem;
        display: inline;
      }
    }
  }

  &.dc-toolbar-btn-full {
    width: auto;
    > .dc-toolbar-btn-title {
      display: inline;
    }
  }
}

button.btn,
a.btn {
  padding: 11px 40px;
  min-width: 200px;
  height: 45px;
  border-radius: 5px;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}

.btn.btn-sm {
  min-width: 150px;
  padding: 11px 30px;
}

.btn.btn-dc-primary {
  background: $color-brand-light;
  border: none;
  color: $white;
  &:hover {
    color: $white;
    background: rgba($color-brand-light, 0.6);
  }
}

.btn.btn-dc-outline {
  background: $white;
  border: 1px solid $color-brand-light;
  color: $color-brand-light;
  &:hover {
    color: $white;
    background: $color-brand-light;
  }
}

.icon-button {
  border: none;
  background: transparent;
}

// new
.dc-icon-button {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 4px;
  color: $color-brand-light;
  cursor: pointer;
  span {
    margin-left: 6px;
  }
  &:hover {
    opacity: 0.7;
  }
}

.dc-icon-filter-button {
  display: inline-flex;
  align-items: center;
  .active {
    background: $color-brand-light;
    color: $white;
    border-radius: 3px;
  }
}

.dc-icon-button-hidden {
  opacity: 0;
  visibility: hidden;
}
