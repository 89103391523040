@import 'constants';

.dc-tabs {
  display: flex;
  height: $tab-height;
  margin-bottom: 30px;
}

.dc-tab {
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  border: 2px solid $color-brand-light;
  color: $color-brand-light;
  border-bottom: none;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  position: relative;
  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &.active {
    background: $color-brand-light;
    color: $white;
    app-svg-icon {
      color: $white;
    }
    .dc-tab-badge {
      color: $white;
    }
  }

  @media (max-width: 1305px) {
    padding: 10px 8px;
  }
}

.dc-tab-icon {
  margin-right: 10px;
}

.dc-tab-badge {
  position: absolute;
  top: 2px;
  right: 4px;
  color: $color-brand-light;
}

.dc-tab--secondary {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid $gray-light;
  margin-right: 0;
  font-weight: 500;
  color: $gray-light;

  .faq-tooltip-brand .tooltip-container .ico fa-icon,
  app-svg-icon {
    color: $gray-light;
  }

  &:not(:last-child) {
    border-right: 1px solid $gray-light;
  }

  &.active {
    background: $white;
    color: $color-brand-light;
    border-bottom: 1px solid transparent;

    .faq-tooltip-brand .tooltip-container .ico fa-icon,
    app-svg-icon {
      color: $color-brand-light;
    }
  }
}
