h1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

h2 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

h3 {
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
}

h4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

@mixin font-regular() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin font-medium() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
}

@mixin table-text {
  font-size: 14px;
  line-height: 16px;
}
