@import 'variables';
@import 'typo';

@mixin rtl {
  @at-root [dir='rtl'] #{&} {
    @content;
  }
}

$ng-select-highlight: #007eff !default;
$ng-select-primary-text: $color-dark-charcoal !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ccc !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 34px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;

.custom-form-input {
  // NG SELECT BOOTSTRAP STYLES
  .ng-select {
    &.bootstrap-select {
      padding-bottom: 0;
      min-width: 400px;

      &.ng-select-opened {
        > .ng-select-container {
          background: $ng-select-bg;
          border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);

          &:hover {
            box-shadow: none;
          }

          .ng-arrow {
            top: -2px;
            border-color: transparent transparent darken($ng-select-border, 20);
            border-width: 0 5px 5px;

            &:hover {
              border-color: transparent transparent darken($ng-select-border, 60);
            }
          }
        }

        &.ng-select-bottom {
          > .ng-select-container {
          }
        }

        &.ng-select-top {
          > .ng-select-container {
          }
        }
      }

      &.ng-select-disabled {
        > .ng-select-container {
          background-color: $ng-select-disabled-text;
        }
      }

      .ng-has-value .ng-placeholder {
        display: none;
      }

      &.is-invalid {
        .ng-select-container {
          background: $color-red-orange-03;
          border: 1px solid $color-red-orange;
        }
      }

      .ng-select-container {
        color: $ng-select-primary-text;
        background-color: $ng-select-bg;
        border-radius: $ng-select-border-radius;
        border: 1px solid $ng-select-border;
        min-height: $ng-select-height;
        align-items: center;

        &:hover {
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        }

        .ng-value-container {
          align-items: center;
          height: 34px;
          padding: 0;
          border-top: 0;
          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 0;
          }

          .ng-input {
            > input {
              height: 100%;
              padding: 8px 0 7px 10px;
              box-sizing: border-box;
            }
          }

          .ng-placeholder {
            color: $ng-select-placeholder;
          }
        }

        &:after {
          content: none;
        }
      }

      &.ng-select-single {
        .ng-select-container {
          height: $ng-select-height;

          .ng-value-container {
            .ng-input {
              top: 0;
              left: 0;
              bottom: 0;
              padding-left: 0;
              padding-right: 50px;
              @include rtl {
                padding-right: $ng-select-value-padding-left;
                padding-left: 50px;
              }
            }

            .ng-value {
              .ng-value-label {
                padding: 8px 0 7px 10px;
              }
            }
          }
        }
      }

      &.ng-select-multiple {
        &.ng-select-disabled {
          > .ng-select-container .ng-value-container .ng-value {
            background-color: $ng-select-disabled-text;
            border: 1px solid lighten($ng-select-border, 10);

            .ng-value-label {
              padding: 0 5px;
            }
          }
        }

        .ng-select-container {
          .ng-value-container {
            padding-top: 5px;
            padding-left: 7px;
            @include rtl {
              padding-right: 7px;
              padding-left: 0;
            }

            .ng-value {
              font-size: $ng-select-value-font-size;
              margin-bottom: 5px;
              background-color: $ng-select-selected;
              border-radius: 2px;
              margin-right: 5px;
              @include rtl {
                margin-right: 0;
                margin-left: 5px;
              }

              &.ng-value-disabled {
                background-color: $ng-select-disabled-text;

                .ng-value-label {
                  padding-left: 5px;
                  @include rtl {
                    padding-left: 0;
                    padding-right: 5px;
                  }
                }
              }

              .ng-value-label {
                display: inline-block;
                padding: 1px 5px;
              }

              .ng-value-icon {
                display: inline-block;
                padding: 1px 5px;

                &:hover {
                  background-color: darken($ng-select-selected, 5);
                }

                &.left {
                  border-right: 1px solid darken($ng-select-selected, 10);
                  @include rtl {
                    border-left: 1px solid darken($ng-select-selected, 10);
                    border-right: none;
                  }
                }

                &.right {
                  border-left: 1px solid darken($ng-select-selected, 10);
                  @include rtl {
                    border-left: 0;
                    border-right: 1px solid darken($ng-select-selected, 10);
                  }
                }
              }
            }

            .ng-input {
              padding: 0 0 3px 3px;
              @include rtl {
                padding: 0 3px 3px 0;
              }
            }

            .ng-placeholder {
              top: 5px;
              padding-bottom: 5px;
              padding-left: 3px;
              @include rtl {
                padding-right: 3px;
                padding-left: 0;
              }
            }
          }
        }
      }

      .ng-clear-wrapper {
        color: darken($ng-select-border, 20);
        bottom: 0;
        align-self: center;
        align-items: center;
        justify-content: center;
        display: flex;

        &:hover .ng-clear {
          color: #d0021b;
        }
      }

      .ng-spinner-zone {
        padding: 5px 5px 0 0;

        @include rtl {
          padding: 5px 0 0 5px;
        }
      }

      .ng-arrow-wrapper {
        width: 25px;
        padding-right: 5px;
        bottom: 0;
        align-self: center;

        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }

        &:hover {
          .ng-arrow {
            border-top-color: darken($ng-select-border, 40);
          }
        }

        .ng-arrow {
          border-color: darken($ng-select-border, 20) transparent transparent;
          border-style: solid;
          border-width: 5px 5px 2.5px;
        }
      }
    }
  }

  .ng-dropdown-panel {
    background-color: $ng-select-bg;
    border: 1px solid $ng-select-border;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;

    &.ng-select-bottom {
      top: 100%;
      border-radius: 4px;
      border-top-color: lighten($ng-select-border, 10);
      margin-top: -1px;

      .ng-dropdown-panel-items {
        .ng-option {
          &:last-child {
            border-radius: 4px;
          }
        }
      }
    }

    &.ng-select-top {
      bottom: 100%;
      border-radius: 4px;
      border-bottom-color: lighten($ng-select-border, 10);
      margin-bottom: -1px;

      .ng-dropdown-panel-items {
        .ng-option {
          &:first-child {
            border-radius: 4px;
          }
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: 1px solid $ng-select-border;
      padding: 5px 7px;
    }

    .ng-dropdown-footer {
      border-top: 1px solid $ng-select-border;
      padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        padding: 8px 10px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;

        &.ng-option-disabled {
          cursor: default;
        }

        &.ng-option-marked {
          background-color: rgba(109, 153, 162, 0.3);
          color: $ng-select-primary-text;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          background-color: $ng-select-selected;
          font-weight: 600;
        }
      }

      .ng-option {
        background-color: $ng-select-bg;
        color: rgba(0, 0, 0, 0.87);
        padding: 8px 10px;
        font-size: 14px;
        min-height: 26px;
        line-height: 26px;
        @include font-regular();

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          color: $ng-select-primary-text;
          background-color: $ng-select-selected;

          .ng-option-label {
            font-weight: 600;
          }
        }

        &.ng-option-marked {
          background-color: rgba(109, 153, 162, 0.3);
          color: $ng-select-primary-text;
        }

        &.ng-option-disabled {
          color: lighten($ng-select-primary-text, 60);
        }

        &.ng-option-child {
          padding-left: 22px;
          @include rtl {
            padding-right: 22px;
            padding-left: 0;
          }
        }

        .ng-tag-label {
          font-size: 80%;
          font-weight: 400;
          padding-right: 5px;
          @include rtl {
            padding-left: 5px;
            padding-right: 0;
          }
        }
      }
    }

    @include rtl {
      direction: rtl;
      text-align: right;
    }
  }
}
