@import 'parts/color';

.noUi-target.noUi-horizontal {
  border: none;
  height: 4px;
  background: $kt-gray;
  border-radius: 0;
  box-shadow: none;
  .noUi-connect {
    background: $dc-cadetblue;
  }

  .noUi-handle {
    border: none;
    background: $dc-cadetblue;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    box-shadow: none;
    &:after,
    &:before {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
}

html:not([dir='rtl']) {
  .noUi-horizontal {
    .noUi-handle {
      right: -12px !important;
    }
  }
}

.dc-slider-labels {
  margin-bottom: 4px;
  font-size: 12px;
}

.dc-slider-result-labels {
  display: flex;
  justify-content: center;
  .min-result {
    margin-right: 20px;
  }
  input {
    @extend .kt-input;
    @extend .kt-small-input;
  }
}
