@import '../variables';

.popover {
  &-header {
    background-color: $white !important;
    border-bottom-color: transparent !important;
  }
}

.spinner-border {
  color: $color-brand-dark;
}

mark,
.mark {
  background-color: $mark-bg !important;
  padding: $mark-padding !important;
}

.popover.preview-file-popover,
.popover.paperclip-popover,
.popover.message-context-popover {
  background-color: transparent;
  border: none;

  .arrow {
    display: none;
  }
}
