$cal-default: #f44336;
$cal-secondary: #c1c1c1;
$cal-active: #8bc34a;
$cal-progress: #dad20e;

app-schedule {
  .cal-month-view {
    .cal-day-number {
      font-size: 16px;
      opacity: 1;
      margin: 0;
    }

    .cal-day-cell {
      text-align: left;
      color: #212529;
      &:not(.cal-in-month) {
        visibility: hidden;
      }
      &:not(:last-child) {
        border: none;
        margin-right: 10px;
      }
    }

    .cal-day-cell.cal-out-month {
      .cal-day-number {
        opacity: 1;
      }
    }

    .cal-day-cell.cal-weekend {
      .cal-day-number {
        color: #212529;
      }
    }

    .cal-day-cell,
    .cal-cell-top {
      min-height: 50px;
      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        // background: $hover;
      }
    }

    .cal-cell-top {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding-top: 10px;
      padding-left: 10px;
    }

    .cal-day-cell {
      min-width: 60px;
      &.cal-today {
        .cal-cell-top {
          border: 3px solid rgba(#212529, 0.5);
        }
        .cal-day-number {
          font-size: 16px;
        }
      }
    }

    .cal-cell {
      align-items: flex-start;
      background-color: rgba($cal-default, 0.5);

      &.cal-today {
        background-color: rgba($cal-default, 0.5);
      }

      &.cal-past.cal-base {
        background-color: rgba($cal-secondary, 0.5);
      }

      &.cal-day-active {
        background-color: rgba($cal-active, 0.5);
      }

      &.cal-day-progress {
        background-color: rgba($cal-progress, 0.5);
      }

      &.cal-day-secondary {
        background: rgba($cal-secondary, 0.5);
      }

      &.cal-day-selected,
      &:hover {
        background-color: $cal-default !important;
        &.cal-day-active {
          background-color: $cal-active !important;
        }

        &.cal-day-progress {
          background-color: $cal-progress !important;
        }

        &.cal-day-secondary {
          background-color: $cal-secondary !important;
        }

        &.cal-base {
          background-color: $cal-default !important;
        }
      }
    }

    .cal-days {
      border: none;
      .cal-cell-row {
        border: none;
        margin-bottom: 10px;
      }
    }

    .cal-cell-row {
      &:hover {
        background: transparent;
      }
      .cal-cell {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .cal-header {
      &:focus {
        outline: none;
      }

      &:hover {
        background: transparent;
      }
      .cal-cell {
        background: transparent;
        pointer-events: none;
        min-width: 60px;
        margin-right: 10px;
      }

      .cal-weekend {
        color: $brand-secondary;
      }
    }
  }

  .calendar-current-month-text {
    text-transform: uppercase;
    font-size: 18px;
    color: #212529;
  }

  .calendar-month-trigger {
    cursor: pointer;
    color: $brand-secondary;
  }

  .cal-month-view .cal-day-cell {
  }
}
