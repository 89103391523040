@media print {
  @page {
    margin: 0;
  }

  * {
    // color: #000 !important;
    //background: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  html,
  body {
    overflow: inherit;
  }
  app-employees-toolbar {
    display: none !important;
  }

  .header1 {
    display: none !important;
  }

  .hidden-printing {
    display: none !important;
  }

  .employees-list {
    height: auto !important;
    overflow: inherit !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .link {
    fill: none;
    stroke: #1c4b59;
    stroke-width: 10px;
  }
}
