@import 'section-form';
@import 'projects';
@import 'filter-templates-form';
@import 'filter-category';
@import 'catalog-filter';
@import 'constants';

.competence-map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .header1 {
    margin-bottom: 0;
  }
}

.competence-map-catalog {
  display: flex;

  &-sections {
    min-width: 400px;
  }

  &-filters {
    min-width: 580px;
  }

  &-experts {
    min-width: 300px;
  }

  &-item {
    padding-right: 40px;
  }
}

.competence-map-scroll-content {
  height: calc(100vh - #{$header-height} - #{$tab-height} - 280px);
  overflow-y: auto;
}

.dc-filter-section {
  margin-bottom: 16px;
  font-size: 12px;
  color: $color-brand-light;
  display: flex;
  align-items: center;

  span {
    margin-right: 6px;
  }
}

.competence-map-section-header {
  line-height: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
  padding-right: 35px;

  &-name {
    display: flex;
    align-items: center;
  }

  &-status {
    display: flex;
    align-items: center;
  }
}

.competence-map-section-toggles {
  .icon-button {
    &:first-child {
      padding-left: 0;
    }
  }
}

.competence-map-header-cell {
  display: flex;
  align-items: center;
}

.competence-map-filter-header {
  margin-bottom: 27px;
}

.competence-map-filters-connected {
  width: 100%;
  padding-right: 32px;
  padding-left: 20px;
  max-width: 330px;
}
