@import "./parts/_color"


.dc-input
  padding: 0
  border: none
  border-bottom-color: $dc-gray-border
  border-bottom-style: solid
  border-bottom-width: 1px
  &.dc-error
    border-bottom-color: red
  &:focus
    outline: none


.dc-clickable
  &:hover
    cursor: pointer


.dc-clickable::before
  &:hover
    cursor: pointer

.dc-modal
  .dc-modal-x-button
    position: absolute
    width: 30px
    height: 30px
    top: -15px
    right: -15px
    margin: 0
    padding: 0
    background-color: white
    border-radius: 50%
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    svg
      color: #3A3A3A !important

.dc-radio
  display: inline-block
  vertical-align: middle
  min-height: 10px
  position: relative
  margin-right: 10px
  padding-left: 35px
  margin-bottom: 12px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  input
    position: absolute
    opacity: 0
  .checkmark
    position: absolute
    top: 0
    left: 0
    height: 20px
    width: 20px
    border-radius: 50%
    border: 1px solid $dc-cadetblue
  &:hover input ~ .checkmark
    background-color: white
  input:checked ~ .checkmark
    background-color: white
  .checkmark:after
    content: ""
    position: absolute
    display: none
  input:checked ~ .checkmark:after
    display: block
  .checkmark:after
    top: 2px
    left: 2px
    width: 14px
    height: 14px
    border-radius: 50%
    background: $dc-cadetblue

.dc-dropdown
  &.dc-error
    .dropdown > button
      border-bottom-color: red
  &.dc-disabled
    opacity: 0.5
    pointer-events: none
  padding: 0 !important
  .dropdown-item:active
    background-color: $dc-cadetblue
  .dropdown, .dropdown-menu, .dropdown-toggle
    width: 100%
    text-align: left
  .dropdown > button
    &:after
      float: right
    color: $dc-gray
    text-decoration: none
    border-bottom: 1px solid $dc-gray-border
    margin-bottom: 10px

.icon-button
  color: $brand
  transition: opacity 0.2s
  &:hover
    opacity: 0.6
  &:focus
    outline: none
  &.disabled
    pointer-events: none
    opacity: 0.5
    cursor: not-allowed
