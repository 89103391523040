$project-status-color: #f4cc7f;
$active-status-color: #39ca74;
$archive-status-color: #df6047;
$template-status-color: #dcdcdc;

.project-status {
  color: $project-status-color;
  fa-icon {
    color: $project-status-color;
  }
}

.active-status {
  color: $active-status-color;
  fa-icon {
    color: $active-status-color;
  }
}

.archive-status {
  color: $archive-status-color;
  fa-icon {
    color: $archive-status-color;
  }
}

.template-status {
  color: $template-status-color;
  fa-icon {
    color: $template-status-color;
  }
}

.tariff-status-select {
  position: relative;
  width: 30px;
  margin: auto;
  &__trigger {
    &.editable {
      cursor: pointer;
    }
  }
  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    background: white;
    z-index: 1;
    padding-top: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 7px;
      li {
        margin-bottom: 10px;
        fa-icon {
          cursor: pointer;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.tariff-group-header-wrapper {
  cursor: pointer;
}

.tariff-group-header {
  font-size: 20px;
  margin-right: 6px;
}

.list-wrapper {
  font-size: 14px;
  height: 90%;
}

.tariffs-list-select-section {
  display: flex;
  align-items: center;
}

.tariffs-list-select-operations {
  display: flex;
  fa-icon {
    opacity: 0.3;
    cursor: pointer;
  }
}

.tariffs-list-remove {
  fa-icon {
    pointer-events: none;
    color: $archive-status-color;
  }
}

.tariffs-list-plug {
  fa-icon {
    color: $active-status-color;
    &:hover {
      opacity: 1;
    }
  }
}

.tariffs-list-select-operations {
  &.active {
    fa-icon {
      opacity: 1;
    }

    .tariffs-list-remove {
      fa-icon {
        pointer-events: inherit;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .tariffs-list-plug {
      fa-icon {
        pointer-events: none;
      }
    }
  }

  &.disabled {
    fa-icon {
      pointer-events: none;
    }
  }
}

.tariffs-list-select-time {
  font-size: 12px;
  color: $active-status-color;
}
