@import '../variables';
@import '../typo';

.profile-container {
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow-y: auto;

  .profile-info {
    color: $color-dark-charcoal;

    .profile-info-header {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 20px;
    }

    .profile-user-contact {
      margin-bottom: 40px;

      .user-contact-header {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 15px;

        .icon-button {
          color: $color-brand-light;
          margin-left: 10px;
          padding: 0;
        }
      }
    }

    .profile-user-contract {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .user-contract-header {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;

        .icon-button {
          color: $color-brand-light;
          margin-left: 10px;
          padding: 0;
        }
      }

      .user-contract-block-container {
        .user-contract-block {
          margin-top: 30px;

          &:first-child {
            margin-top: 20px;
          }

          &.user-contract-natural-person-info {
            margin-top: 20px;
          }

          &.user-contract-parent-user {
            margin-top: 15px;
          }
        }
      }
    }

    .user-contract-header2 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 10px;

      .icon-button {
        color: $color-brand-light;
        margin-left: 10px;
        padding: 0;
      }
    }
  }

  .profile-documents {
    .profile-document-links {
      .card {
        background-color: transparent;
        border: none;
        border-radius: 0;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .card-header {
          padding: 0;
          margin: 0;
          background-color: transparent;
          border: none;

          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @include font-regular();
            font-size: 16px;
            line-height: 19px;
            color: $color-brand-light;
            text-align: left;
            vertical-align: middle;
            user-select: none;
            background-color: rgba(109, 153, 162, 0.15);
            border: 1px solid transparent;
            padding: 5px 10px;
            border-radius: 0;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;

            &:focus {
              outline: none;
            }

            &.collapsed {
              background: transparent;
            }
          }
        }

        .card-body {
          padding: 0;

          .profile-document-group {
            .profile-document-item {
              cursor: pointer;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              text-decoration-line: underline;
              color: #1c4b59;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 35px;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }

              &:first-child {
                margin-top: 5px;
              }

              &:hover {
                background-color: rgba(109, 153, 162, 0.3);
              }
            }
          }
        }
      }
    }
  }

  .label-tr {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .text-medium-normal {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.profile-form-content {
  app-datepicker {
    margin-bottom: 10px;

    dc-form-field-wrapper {
      margin-bottom: 0;
    }

    .datapicker-error {
      margin-left: 10px;
    }
  }

  dc-form-field-wrapper {
    margin-bottom: 10px;

    &:last-child:not(:first-child) {
      margin-bottom: 0;
    }

    dc-form-input {
      display: flex;
      margin-right: 10px;
    }

    .form-field-error {
      margin-left: 10px;
    }
  }

  app-datepicker {
    align-items: center;

    dc-form-field-wrapper {
      width: auto;
    }
  }
}

.profile-form-contact {
  dc-form-field-wrapper {
    .dc-form-field {
      &.bootstrap {
        input {
          width: 230px;
        }
      }

      &.required {
        label {
          &:after {
            content: '*';
            color: $color-red-orange;
          }
        }
      }
    }

    .phones {
      display: flex;

      > div {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }

      .phone-form-field {
        position: relative;

        input {
          padding: 8px 20px 7px 10px;
        }

        .icon-button {
          position: absolute;
          top: 0;
          right: 5px;
          align-items: center;
          height: 100%;
        }
      }
    }
  }
}

.profile-form-contract {
  .radio-group {
    display: flex;

    &.required {
      .radio-group-title {
        &:after {
          content: '*';
          color: $color-red-orange;
        }
      }
    }

    .radio-group-title {
      min-width: 200px;
      @include font-medium();
      font-size: 14px;
      line-height: 21px;
    }
  }

  dc-form-field-wrapper {
    .dc-form-field {
      &.inline-field {
        label {
          width: 200px;
          line-height: 1;
        }
      }

      &.required {
        label {
          &:after {
            content: '*';
            color: $color-red-orange;
          }
        }
      }

      &.bootstrap {
        textarea {
          width: 550px;
          height: 60px;
        }

        input {
          &.passport_series {
            width: 60px;
            margin-right: 10px;
          }

          &.passport_number {
            width: 80px;
          }

          &.passport_division_code {
            width: 90px;
          }

          &.itn {
            width: 130px;
          }

          &.snils {
            width: 150px;
          }

          &.address_postcode {
            width: 78px;
          }

          &.bankcard_number {
            width: 205px;
          }

          &.bankcard_bank_name {
            width: 550px;
          }

          &.psrnsp {
            width: 158px;
          }
          &.certificate_number {
            width: 202px;
          }

          &.organization_type {
            width: 400px;
          }

          &.iec {
            width: 105px;
          }

          &.psrn {
            width: 140px;
          }

          &.authorized_person {
            width: 230px;
          }

          &.bank_name {
            width: 542px;
          }

          &.bank_bic {
            width: 105px;
          }

          &.bank_swift {
            width: 122px;
          }

          &.bank_current_account,
          &.bank_corresponding_account {
            width: 204px;
          }

          &.bank_corresponding_account_other {
            width: 328px;
          }

          &.bank_current_account_other {
            width: 220px;
          }
        }
      }
    }

    .custom-form-input {
      .ng-select {
        &.bootstrap-select {
          width: 400px;
        }
      }
    }
  }
}

.profile-form {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .icon-button {
    color: $color-brand-light;
    margin-left: 10px;
    padding: 0;
  }

  .profile-form-header {
    margin-bottom: 20px;

    .header-primary {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 20px;
    }

    .header-secondary {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;

      .icon-button {
        margin-left: 10px;
        padding: 0;
      }
    }
  }

  .profile-form-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    .form-body {
      flex: 1;
      justify-content: flex-end;
    }

    .form-actions {
      > button {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .form-section {
      margin-bottom: 40px;

      .form-section-header {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 30px;
      }
    }
  }

  .contract-rule {
    @include font-regular();
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: $color-brand-middle;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      margin-left: 5px;
      text-decoration: underline;
      color: $color-brand-middle;
    }
  }
}

.profile-password-form {
  margin-top: 10px;

  .form-body {
    dc-form-field-wrapper {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.password-requirements {
  margin-top: 20px;

  &.success {
    .password-requirement {
      .password-requirement-icon {
        color: $color-mantis;
      }
    }
  }

  .password-requirement {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;

    &.invalid {
      color: $color-red-orange;
    }

    &.valid {
      .password-requirement-icon {
        color: $color-mantis;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .password-requirement-icon {
      margin-right: 5px;
      color: $color-red-orange;
    }
  }
}

.profile-modal {
  .dc-modal-x-button {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

    app-svg-modal-close {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $color-brand-dark;
      }
    }
  }

  .modal-header {
    margin-bottom: 20px;
  }

  .modal-footer {
    justify-content: center;

    & > :not(:first-child) {
      margin-left: 30px;
    }
  }
}
