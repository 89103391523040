.section-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 50px;
}

.section-header-info {
  min-width: 222px;
}

.section-header-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.section-header-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section-header-label {
  color: $color-brand-light;
  margin-right: 5px;
  font-weight: 500;
}

.section-path {
  font-size: 16px;
  margin-bottom: 30px;
}

.section-add-new {
  display: inline-flex;
  color: $color-brand-light;
  font-size: 14px;
  align-items: center;
  cursor: pointer;

  app-svg-icon {
    margin-right: 5px;
  }
}

.section-header-archive {
  display: flex;
  align-items: center;
  color: $color-brand-light;
  font-size: 14px;
  cursor: pointer;

  span {
    margin-top: 4px;
  }

  app-svg-icon {
    margin-right: 5px;
  }
}

.section-modal {
  .modal-dialog {
    max-width: 750px;
  }
}

.filter-template-modal {
  .modal-dialog {
    max-width: 1050px;
  }
}

.user-filter-modal {
  .modal-dialog {
    max-width: 800px;
  }
}

.section-split-modal {
  .modal-dialog {
    max-width: 1100px;
  }

  .dc-footer-buttons {
    justify-content: center;
  }
}

.project-history-modal {
  .modal-dialog {
    max-width: 620px;
  }
}

.section-form-fields {
  .array-sections {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.section-modal-actions {
  h5 {
    margin-bottom: 20px;
  }

  .modal-dialog {
    max-width: 390px;
  }
}

.section-modal-action {
  margin-bottom: 10px;
  color: $color-brand-light;
  display: flex;
  align-items: center;
  cursor: pointer;

  app-svg-icon {
    margin-right: 10px;
  }
}

.section-modal-reset {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  app-svg-icon {
    margin-right: 10px;
    color: $color-brand-light;
  }
}
