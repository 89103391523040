@mixin buttons-position {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}

.tariff-modal-header {
  text-align: center;
  padding-bottom: 20px;
}

.tariff-modal-buttons {
  @include buttons-position;
  margin-top: 3rem;
}
