@import 'assets/scss/variables';

.file-manager {
  mark {
    background: rgba($brand, 0.3);
    padding: 3px 0;
  }
}

.dc-fileman-modal-x-button {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -15px;
  right: -15px;
  margin: 0;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 5px 15px rgba(28, 75, 89, 0.2));
  box-shadow: 0 5px 15px rgba(28, 75, 89, 0.2);

  svg {
    color: $color-brand-dark;
  }
}

// OLD
.fileman-modal-zips {
  height: 100%;
  .modal-content {
    height: 600px;
  }
}

app-file-manager-tree {
  display: block;
}

.fileman-left-menu {
  //max-height: 489px;
  overflow-y: auto;
  display: block;
  .btn-link {
    &__info {
      color: $color-brand-light;
      background-color: #fff;
      position: absolute;
      top: 0;
      border: none;
      outline: none;
      right: 6px;
    }
    &__share {
      right: 0;
    }
  }
}

.list-second {
  margin-left: 20px;
}

.file-manager-tree-text {
  display: flex;
  position: relative;
  overflow: hidden;
  mark {
    background: rgba($brand, 0.3);
    padding: 3px 0;
  }
}

.file-manager-tree-empty {
  margin-left: 20px;
}

app-competencies-filter {
  .cbx-label {
    max-width: 350px;
  }

  .cbx-label-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.users-share-filter-modal {
  .modal-dialog {
    max-width: 700px;
  }
}

.fileman-file-list-container,
.fileman-container-list {
  mark {
    background: rgba($brand, 0.3);
    padding: 3px 0;
  }
}

.fileman-ul-container-list-link {
  max-width: 300px;
}

.fileman-container-left-menu {
  width: 28%;
  padding-right: 13px;
}

.reset-search {
  color: $color-brand-light;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 4px;
  margin: 0;
  padding: 0;
}

.fileman-container-right-menu {
  width: 72%;
}

.fileman-ul-container-list-access-checkbox {
  height: 20px;
}

.file-manager-tree-icon {
  &__black {
    color: $black;
  }
}

.fileman-left-menu {
  .folder-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    .file-manager-tree-icon-group {
      display: flex;
      justify-content: space-between;
      width: 35px;
      &-file {
        justify-content: flex-end;
        padding-right: 3px;
        &_node {
          justify-content: flex-start;
          width: auto;
        }
      }
    }

    &__root {
      width: 100%;
      justify-content: flex-start;
      margin-left: 0;
      padding-left: 0;
      &_opened {
        background-color: lighten($color-brand-light, 30) !important;
      }
      span {
        text-decoration: underline;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        vertical-align: middle;
        text-overflow: ellipsis;
      }

      span,
      app-svg-icon {
        color: $color-brand-middle;
      }
    }

    &.btn-link {
      background-color: inherit;
    }
  }
}
