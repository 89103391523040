@import 'variables';

@mixin mark {
  position: absolute;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: $white;
  border: 2px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $color-brand-light;
}

.radio-brand {
  display: flex;

  .radio-item {
    flex: 41%;

    &:first-of-type {
      flex: 40%;
    }
  }

  .input-brand {
    position: absolute;
    opacity: 0;
  }

  .radio-brand-label {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  .mark-brand {
    @include mark;
  }

  .input-brand:checked ~ .mark-brand {
    background-color: $color-brand-light;
  }
}

.radio-brand-filter {
  display: flex;
  flex-direction: column;

  .input-brand {
    position: absolute;
    appearance: none;
    opacity: 0;
  }

  &-label {
    display: flex;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  &-text {
    margin-left: 10px;
  }

  .mark-brand-radio {
    @include mark;
    top: 2px;
  }

  .input-brand:checked ~ .radio-brand-filter-label {
    .mark-brand-radio {
      background-color: $color-brand-light;
    }
  }
}
