@import '../variables';

.filter-types-edit-row {
  margin-bottom: 20px;
  display: flex;
  .dc-radio-input {
    width: 125px;
    min-width: 125px;
    margin-right: 20px;
  }

  .switch {
    margin: 0 5px;
  }
}

.filter-template-name {
  .dc-form-field {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
