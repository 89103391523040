.xbox {
  border: 1px solid $brand;
  width: 20px;
  height: 20px;
  color: $white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
  fa-icon {
    opacity: 0;
    color: $white;
    display: inline-flex;
  }

  &.active {
    background: $brand;
    opacity: 1;
    fa-icon {
      opacity: 1;
    }
  }
}
