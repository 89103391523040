@import '../parts/color';
@import 'mixins';

// table

.trades-wrapper .grid-container {
  padding: 0;
  opacity: 1;

  &:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  &:nth-child(2) {
    position: sticky;
    left: 70px;
    z-index: 1;
  }

  &:not(:last-child) {
    border-right: 1px solid $gray-light;
  }
}

.grid-container {
  padding: 0 10px 20px;
}

.ul-padding {
  padding-top: 10px;
}

.small-equal {
  font-size: 10px;
  color: #707070;
  margin-right: 5px;
}

// inputs
.kt-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  transition: 0.5s ease;
  height: 20px;
  padding-left: 0;
  padding-right: 0;

  &:focus {
    border-bottom: #dbdbdb 1px solid;
    transition: 0.5s ease;
    outline: none;
  }
}

.kt-small-input {
  width: 50px;
}

.kt-small2-input {
  width: 75px;
}

.kt-mid-input {
  width: 120px;
}

// grid options modal

.grid-options-modal {
  font-size: 14px;

  .modal-dialog {
    max-width: 700px;
    margin: 5.6rem auto;
  }
}

.competencies-modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;

  .modal-header {
    padding-left: 17.5px;
  }

  .modal-content {
    width: 700px;
  }
}

// type trade card top offset block
.trade-card-tab-content {
  padding-top: 30px;
}

.service-menu {
  position: absolute;
  top: -6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  left: -5px;
  background: #fff;
  text-align: center;
  color: $brand;
  border-radius: 5px;
  padding: 0;
  z-index: 10;

  &-item {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;

    &:not(.without-hover):hover {
      background-color: #d4e1e4;
    }

    label {
      white-space: nowrap;
      margin-bottom: 0;
    }

    span {
      white-space: nowrap;
    }
  }
}

.counter {
  display: block;
  margin-left: 20px;
  width: 35px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 10px;
  color: #707070 !important;
}

.supplier-bg {
  position: relative;
  overflow: initial;

  &.active {
    background: #bbffbd;
  }

  &.inactive {
    background: #ff9a9a;
    color: white;

    a {
      color: white;
    }
  }
}

.name {
  font-weight: 500;
  margin-right: 1px;
}

.trades-wrapper {
  font-size: 14px;
  height: calc(100% - 105px);
  padding: 20px;
  padding-top: 0;

  &--supplier {
    height: calc(100% - 61px);
  }
}

.kt-toggler {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.kt-toggler-inner {
  width: 220px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid $gray-light;

  fa-icon {
    background: rgb(5, 4, 4);
    position: relative;
    z-index: 1;
    width: 20px;
    text-align: center;
  }
}

.toggled + .kt-toggler {
  padding-top: 0;

  .kt-toggler-inner {
    border-top: 1px solid $gray-light;
    border-bottom: 0;
  }

  .kt-toggler-icon {
    align-items: flex-end;
    top: -2px;
  }
}

.kt-toggler-icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 20px;
  height: 10px;
  background-color: #fff;
  z-index: 1;
  top: 2px;
  position: relative;

  app-svg-icon {
    color: $gray-light;
  }
}

app-terms-trade-system {
  .mat-grid-tile {
    overflow: inherit;
  }
}

.bidding-picker {
  position: absolute;
  left: 0;
  top: 0;
}

.bidding-date-icon {
  cursor: pointer;
}

.trade-name {
  word-break: break-all;
}

.trade-document-link {
  width: 110px;
}

.trade-cell-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 15px);
  display: flex;
  align-items: center;
  justify-content: center;

  app-svg-icon {
    display: inline-block;
  }
}

.new-template-modal .modal-body {
  display: flex;
  flex-direction: column;
  height: 240px;
}

.trades-wrapper .p-10 {
  padding: 10px;
}

.typepage {
  height: 100%;
}

.date-range dc-form-field-wrapper .dc-form-field.inline-field label {
  min-width: 20px;
  font-weight: 400;
}

.date-range app-datepicker .datapicker-error {
  margin-top: 5px;
  max-width: 150px;
  font-size: 12px;
  line-height: 14px;
  white-space: unset;
}

.criteria-cell-value__variants.tooltip {
  margin-left: -12px;
  padding: 5px;
  min-width: 215px;
  z-index: 4;

  .tooltip-inner {
    max-width: none;
    text-align: left;
  }

  .arrow {
    display: none;
  }

  .variants {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }

  .variant {
    display: flex;
    flex-direction: column;

    &-description {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-vector {
      color: #6d99a2;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }
}
