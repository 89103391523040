.tariffs-list {
  max-height: 300px;
  min-height: 300px;
  .grid-menu {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    column-gap: 40px;
  }
}

.tariff-info {
  text-overflow: ellipsis;
  word-break: break-word;
  height: 32px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
