$cbx-size: 20px;

.cbx-input {
  display: none;
}

.cbx-label {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;

  &::before {
    content: '';
    width: $cbx-size;
    height: $cbx-size;
    min-width: $cbx-size;
    display: block;
    border: 1px solid $color-brand-light;
    border-radius: 3px;
    vertical-align: middle;
    transition: background 0.1s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 7px;
    margin-top: -7px;
    width: 6px;
    height: 12px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }

  &.cbx-label-interminate {
    &::before {
      border: none;
      border-radius: 0;
      background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.72295 8.60562L3.72292 8.60564L2.71279 9.61578C2.2386 10.09 2.2386 10.8588 2.71279 11.333L7.35562 15.9758C7.35563 15.9759 7.35563 15.9759 7.35564 15.9759C7.82988 16.4502 8.5987 16.4502 9.07293 15.9759L17.2872 7.76163C17.7614 7.28744 17.7614 6.51858 17.2872 6.04439L16.2771 5.03426C15.8029 4.56009 15.0341 4.56 14.5598 5.03426L8.21429 11.3797L5.44021 8.60564C4.966 8.13144 4.19716 8.13148 3.72295 8.60562ZM17.8571 19.5H2.14286C1.23552 19.5 0.5 18.7645 0.5 17.8571V2.14286C0.5 1.23552 1.23552 0.5 2.14286 0.5H17.8571C18.7645 0.5 19.5 1.23552 19.5 2.14286V17.8571C19.5 18.7645 18.7645 19.5 17.8571 19.5Z' stroke='%236D99A2'/%3E%3C/svg%3E%0A") !important;
    }

    &::after {
      display: none;
    }
  }
}

.cbx-label-text {
  margin-left: 10px;
}

.cbx-input:checked ~ .cbx-label {
  &::before {
    border-color: transparent;
    background: $color-brand-light;
  }

  &::after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
}

.cbx-input:checked:disabled ~ .cbx-label {
  cursor: default;

  &::before {
    background: $color-brand-light;
  }
}

.cbx-input:disabled ~ .cbx-label {
  cursor: default;
  opacity: 0.5;
}
