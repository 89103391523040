@import 'variables';

.schedule-timeline-time {
  padding: 2px 7px;
  width: 43px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  background: $red-calendar;
  &.active {
    background: $green;
  }

  &.process {
    background: $yellow;
  }

  &.time-secondary {
    background: rgba($cal-secondary, 0.5);
  }
}

$duty-list-padding: 0 10px 4px;

// remove in setup-duty-tso
.duty-list-table {
  thead {
    td {
      font-weight: bold;
      font-size: 14px;
      padding: $duty-list-padding;
    }
    .cbx-label-text {
      font-weight: bold;
    }
  }

  tbody {
    td {
      font-size: 12px;
      padding: $duty-list-padding;
    }
  }

  td {
    vertical-align: top;
  }
}

.timeline-item-users {
  @extend .text-overflow;
}
