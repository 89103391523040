.dc-dropdown-menu {
  position: absolute;
  top: 35px;
  left: 0;
  width: 400px;
  max-height: 225px;
  background: $white;
  border: 1px solid $gray-light;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 10;
}

.dc-dropdown-menu-inner {
}

.dc-dropdown-menu-item {
  font-size: 14px;
  height: 26px;
  padding: 5px 10px;
  transition: all 0.24s;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: $color-brand-light;
    color: $white;
  }
}
