.kt-report-sec1 {
  display: flex;
  padding-top: 20px;
}

.kt-report-sec3 {
  padding-top: 50px;
  display: grid;
  grid-template-columns: 3fr 3fr;
  column-gap: 30px;
}

.kt-report-sec3-reason {
  padding-top: 0;
}

.kt-report-sec6,
.kt-report-sec-9 {
  padding-top: 50px;
}

.kt-report-sec7 {
  padding-top: 50px;
  display: grid;
  grid-template-columns: minmax(350px, max-content) auto;
}

.kt-report-sec8 {
  max-width: 80%;
  column-gap: 30px;
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
}

.kt-report-sec8-label {
  height: 60px;
}

.report-round {
  background: $brand;
  color: #fff;
  font-size: 32px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
}

.report-round .inside {
  background: $brand;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: absolute;
  margin-left: 30px;
  margin-top: -80px;
}

/* trade results */

.kt-rep-result {
  display: grid;
  grid-template-columns: 600px 300px auto;
}

.kt-rep-result-item1 {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  column-gap: 5px;
  grid-template-columns: 50px repeat(3, 20px) 200px repeat(8, 20px) 200px repeat(2, 35px) 100px auto;
  grid-row-gap: 5px;
}
.kt-rep-result-rep {
  grid-column-start: 6;
  grid-column-end: 14;
}
.kt-rep-result-rep2 {
  grid-column-start: 15;
  grid-column-end: 17;
}
.kt-rep-result-scores {
  grid-column-start: 17;
  grid-column-end: 19;
}

.temp-checkbox {
  height: 14px;
  width: 14px;
}

.kt-rep-result-item2 {
  grid-column-start: 1;
  grid-column-end: 4;

  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
  column-gap: 20px;
  padding-left: 50px;
}

// customer/supplier company info

.kt-report-sec2 {
  padding-top: 50px;
  display: flex;
  width: 90%;
  flex-direction: column;
}

.kt-report-sec2-item {
  padding-top: 10px;
  width: 165px;
  padding-right: 10px;
}

.kt-report-sec2-head,
.kt-report-sec2-body {
  display: flex;
}
