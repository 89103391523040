@import 'variables';

.tariff-container {
  height: 100%;
  padding: 20px;
  overflow: hidden;

  .dc-tabs {
    margin-bottom: 20px;
  }

  .dc-tab-content {
    height: 80%;
    max-height: 100%;
    overflow: auto;
  }
}

.tariff-map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .header1 {
    margin-bottom: 0;
  }
}

.table-container {
  position: relative;
  max-width: 100%;
  width: fit-content;
  height: calc(100% - 50px);
  overflow: auto;
  color: $color-dark-charcoal;
  border: 1px solid $gray-light;
  border-radius: 4px;

  .tariff {
    th {
      padding: 0;
    }

    .table-tariff-responsive {
      height: calc(100% - 50px);
      overflow-y: auto;
      margin-top: 10px;
      width: fit-content;

      .loading-wrapper {
        padding: 10px;
      }
    }

    .grid-wrapper:not(:last-child) {
      padding: 0;
      line-height: normal;
    }

    .tariff-table-header {
      height: 51px;
      box-shadow: 0 1px 0 0 $gray-light;
      cursor: grab;
      position: sticky;
      top: 0;
      z-index: 300;

      .col {
        min-height: 51px;
        padding: 12px 12px 0 12px;
        border: none;
        font-weight: 500;
        background-color: $aqua-gray;
        box-shadow: 1px 0 0 0 $gray-light;

        &:last-child {
          border-right: none;
        }
      }
    }

    .table-row {
      border-bottom: 1px solid $gray-light;

      .table-cell {
        box-shadow: inset -2px 0 0 -1px $gray-light;
        padding: 0;
      }
    }

    .col {
      display: flex;
      justify-content: space-between;
      height: 50px;
      padding: 12px 12px 0 12px;
      border: none;
      font-weight: 500;
      background-color: $aqua-gray;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.current-tariff {
  background-color: $gray;
}

.selected-tariff {
  background-color: $gray-light-input;
}

.tariff-col {
  display: flex;
  column-gap: 8px;
  padding: 12px 12px 0 12px;
  border: none;
  font-weight: 500;
  background-color: $aqua-gray;
  min-height: 51px;
  box-shadow: inset -2px 0 0 -1px $gray-light;

  &:last-child {
    border-right: none;
  }

  .tariff-header-buttons {
    display: flex;
    justify-content: start;
    column-gap: 5px;
    width: 40px;

    .icon-buttons {
      color: $color-brand-light;
      transition: ease 0.3s;
      cursor: pointer;
    }
  }
}
