@import 'variables';
@import 'typo';

@mixin buttons-position {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}

.role-select {
  .ng-dropdown-panel.ng-select-bottom {
    top: 38px;
  }
  &.ng-select {
    padding-bottom: 0;

    input {
      padding: 0 10px !important;
    }

    &.ng-select-opened {
      .ng-value-container {
        > .ng-placeholder {
          opacity: 0;
        }
      }
    }

    &.ng-select-focused {
      .ng-value-container {
        > .ng-placeholder {
          opacity: 0;
        }
      }
    }

    .ng-select-container {
      min-height: 38px;
      border: 1px solid $gray-light;
      border-radius: 5px;
      transition: 0.3s;

      &:after {
        display: none;
      }

      .ng-clear-wrapper {
        bottom: 3px;
      }

      .ng-arrow-wrapper {
        bottom: 6px;
        right: 5px;
      }

      .ng-value-container {
        padding: 0 10px;
        border-top: 0 solid transparent;

        > .ng-placeholder {
          display: none;
          bottom: 5px;
          opacity: 0.6;
        }

        > .ng-value {
          margin-top: 8px;

          & > input {
            padding: 0 10px;
          }
        }
      }

      &.ng-has-value {
        .ng-value-container {
          > .ng-placeholder {
            opacity: 0;
          }
        }
      }
    }

    &.ng-invalid.ng-touched {
      .ng-select-container {
        background: #fde1e1;
        border-color: #f44336;
        transition: 0.3s;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        border-color: $color-brand-light;
        transition: 0.3s;
      }
    }
  }
}

/* Статусы */

.status-green {
  color: #2ecc71;
}

.status-red {
  color: #e15f41;
}

.status-blue {
  color: #546de5;
}

.status-yellow {
  color: #f5cd79;
}

.users-table-header {
  height: 51px;
  border-bottom: 1px solid $gray-light;
  cursor: grab;
  position: sticky;
  top: 0;
  z-index: 300;

  .col {
    min-height: 50px;
    padding: 12px 12px 0 12px;
    border: none;
    font-weight: 500;
    background-color: $aqua-gray;

    &:last-child {
      border-right: none;
    }
  }
}

.users-table {
  white-space: nowrap; /*чоб не переносило предложения */

  .table-row {
    width: 100%;
    border-bottom: 1px solid $gray-light;

    .col-sticky {
      position: sticky;
      z-index: 99;
    }
  }

  .col {
    min-height: 60px;
    padding: 12px;
    border: none;
    border-right: 1px solid #e8e8e8;
    white-space: nowrap;

    &.col-ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:last-child {
      border-right: none;
    }

    &:nth-last-child(2) {
      border-right: none;
    }

    .employee-card-cell {
      display: flex;
      justify-content: space-between;

      .employee-card-cell-name-wrapper {
        padding-top: 0.2rem;
        overflow: hidden;

        .employee-card-cell-name {
          max-width: 8rem;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.bwl {
  .fas {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.grade-expand {
  color: $brand;
  cursor: pointer;
}

.tail {
  background-color: transparent !important;
  padding: 0 !important;
}

.no-tail {
  background-color: transparent !important;
}

.open-compet-wrapper {
  min-width: 450px;
  max-height: 700px;
  z-index: 99;
}

.open-compet {
  overflow-y: scroll;
  height: 100%;
}

.no-wrap {
  flex-wrap: nowrap;
}

.employee-setting-modal {
  min-width: 700px;

  .modal-dialog {
    max-width: 800px;
    margin: 5.6rem auto;
  }
}

.employee-tso-modal {
  min-width: 700px;

  .modal-dialog {
    max-width: 1300px;
  }
}

.employee-modal-mtop {
  > .modal-dialog {
    margin: 0 auto;
  }
}

.employees-filter-modal {
  &.employees-filter-rating {
    .modal-dialog {
      max-width: 350px;

      .list-container {
        .list-row {
          justify-content: space-between;
        }
      }
    }
  }

  &.employees-filter-bidding {
    .modal-dialog {
      max-width: 250px;
    }
  }

  .list-row-value {
    display: flex;
  }

  .datepicker-input {
    &::-webkit-input-placeholder {
      opacity: 0.5;
    }

    &::-moz-placeholder {
      opacity: 0.5;
    }

    &:-ms-input-placeholder {
      opacity: 0.5;
    }

    &:-moz-placeholder {
      opacity: 0.5;
    }
  }
}

.close-button {
  cursor: pointer;
  position: absolute;
  z-index: 100001;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0 5px 15px $color-brand-dark;
  text-align: center;
  background: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-brand-dark;
}

.confirm-modal {
  font-size: 14px;
  width: 510px;
  padding: 0;
  margin: 0;
  text-align: left;

  .modal-content {
    width: 510px;
  }

  .modal-body {
    padding: 40px;
  }

  .modal-footer {
    border: none;
    padding: 0;
    justify-content: space-between;
    > * {
      margin: 0;
    }
  }
}

.buttons-action-wrapper {
  @include buttons-position;
  margin-top: 5rem;
}

.buttons-notification-wrapper {
  @include buttons-position;
  margin-top: 2rem;
}

.visibility-hidden {
  visibility: hidden;
}

.confirm-status-modal {
  font-size: 16px;
  width: 510px;
  padding: 0;
  margin: 0;
  text-align: left;

  .modal-dialog {
    max-width: 510px;
  }

  .modal-body {
    padding: 40px;
  }

  .modal-footer {
    border: none;
    padding: 0;
    justify-content: space-between;
    > * {
      margin: 0;
    }
  }
}

.color-yellow {
  background-color: $yellow;
}

.font-bold {
  @include font-medium();
}
