// Переоформление основных элементов

@import "parts/_color"

h1, .h1, .header1
  font-size: 1.5rem !important
  font-weight: 500
  white-space: nowrap

h2, .h2, .header2
  font-size: 1.125rem !important
  font-weight: 500
  white-space: nowrap

.bold, .nav, .error
  font-weight: bold

.label-medium
  font-size: 1rem !important
  font-weight: 500
  margin-bottom: 5px
  white-space: nowrap

.text-medium-light
  font-size: 1rem !important
  font-weight: 300

.text-medium-normal
  font-size: 1rem !important
  font-weight: normal

.no-wrap
  white-space: nowrap
