.dc-slider {
  width: 60%;
  .ngx-slider {
    margin: 0;
    .ngx-slider-pointer {
      width: 10px;
      height: 10px;
      background-color: $color-brand-light;
      border-radius: 100%;
      top: -4px;
      outline: none;
      &:after {
        display: none;
      }
    }
    .ngx-slider-bar {
      height: 2px;
      background: $gray-light;
    }

    .ngx-slider-selection {
      background: $color-brand-light;
    }
  }

  .ngx-slider-wrapper {
    position: relative;
  }

  .ngx-slider-range {
    position: absolute;
    width: 10px;
    height: 10px;
    background: $gray-light;
    border-radius: 100%;
    z-index: 2;
    cursor: pointer;
    top: 50%;
    margin-top: -5px;
    &--from {
      left: 0;
    }
    &--to {
      right: 0;
    }
  }
}
