@media print {
  @page {
    margin: 0;
  }

  * {
    color: #000 !important;
    background: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  html,
  body {
    overflow: inherit;
  }

  app-status-bar {
    display: none;
  }

  app-trade-grid-operations {
    display: none;
  }
  .left-menu {
    display: none;
  }
  .fit-content {
    width: 100%;
    display: block;
  }

  .main-container {
    height: auto !important;
    overflow: inherit;
  }

  .thead-operations {
    display: none;
  }

  .main-content {
    width: 100% !important;
    overflow: inherit !important;
  }

  .grid-layout-body {
    height: auto !important;
  }

  .trades-wrapper {
    font-size: 9px !important;
    height: auto;
    overflow-y: inherit;
    overflow-x: hidden;
    .save-button {
      display: none;
    }
    .chevron-up {
      display: none;
    }
  }

  .grid-layout {
    display: grid !important;
    grid-template-columns: 30px repeat(2, 100px) repeat(2, 80px) 80px 130px repeat(7, 100px) 110px repeat(2, 80px) !important;
  }

  .grid-layout-body {
    height: auto !important;
    overflow-y: inherit;
  }

  .grid-container {
    width: auto !important;
    overflow: hidden !important;
  }

  .kt-instrument {
    display: none;
  }

  .grid-menu {
    display: none;
  }

  .kt-report-sb {
    display: none;
  }

  .trade-card-tab-content {
    padding-top: 0;
  }

  .trade-name {
    word-break: break-word !important;
    overflow: hidden !important;
    display: block !important;
    text-overflow: ellipsis !important;
    width: 50px;
  }

  .save-button {
    display: none !important;
  }

  app-faq-tooltip {
    display: none !important;
  }
}
