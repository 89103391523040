.change-tso-modal {
  .modal-dialog {
    max-width: 820px;
  }
}

.chat-duty-tso-modal {
  min-width: 700px;
  overflow: hidden !important;

  .modal-dialog {
    max-width: 1200px;
  }
}

.chat-tso-options-modal {
  .modal-dialog {
    max-width: 450px;

    .modal-content {
      height: 515px;
    }
  }
}

.group-title-text {
  display: block;
  width: 201px;
  margin-right: 20px;

  > span {
    &:focus {
      outline: none;
    }
  }
}

.chat-number {
  background: rgba($color-brand-light, 50%);
  font-size: 9px;
  font-weight: 900;
  border-radius: 50%;
  min-width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  color: $color-dark-charcoal;
}

.chat-number__10 {
  margin-right: 0 !important;
  width: 20px;
  border-radius: 7px;
}
