$content-width: 900px;

@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:300,400,500,600,700&amp;subset=cyrillic');

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: Roboto, 'Helvetica Neue', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif !important;
  min-width: 1200px;
  line-height: 1.2;
}

label {
  font-weight: 400;
}

.inline {
  display: flex;

  &.start {
    align-items: flex-start;
  }

  &.middle {
    align-items: center;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }

  &.row-wrap {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.align-content-end {
    align-content: end;
  }
}

.simple-notification-wrapper {
  z-index: 1200 !important;
}

div.simple-notification {
  width: 400px;
  padding: 20px 20px 25px;

  &.warn {
    background: $brand-warn;

    div.sn-title {
      color: white;
    }

    div.sn-content {
      color: white;
    }
  }

  &.notify-class .icon {
    padding: 20px;
    width: 60px;
    height: 60px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .sn-title {
    word-wrap: break-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .sn-content {
    word-wrap: break-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
    text-transform: none;
  }
}

.content-width {
  min-width: $content-width;
}

input.has-error {
  color: #e6490b !important;
  background-color: rgb(197 91 48 / 30%);
}

.no-left-margin {
  margin-left: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.hidden-xs-up {
  display: none !important;
}

.typepage {
  padding-top: 20px;
  min-width: 1200px;
}

a {
  &:focus {
    outline: none !important;
  }
}

.disabled,
._disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default !important;
}

.inactive {
  opacity: 0.5;
}

.modal-window {
  &.file-manager-modal {
    .modal-dialog {
      max-width: 1100px;
    }
  }

  &.trades-filter-modal {
    .modal-dialog {
      max-width: 450px;
    }
  }

  &.employees-filter-modal {
    .modal-dialog {
      max-width: 450px;
      margin: 13rem auto;
    }
  }
}

.duty-modal {
  margin-top: 15rem;
}

.faq-tooltip-top-right {
  position: absolute;
  top: -19px;
  right: -17px;
}

.faq-tooltip-top-left {
  position: absolute;
  top: -18px;
  left: -20px;
}

.faq-tooltip {
  &-sm .tooltip-container .ico {
    font-size: 14px;
    height: 18px;
  }

  &-md .tooltip-container .ico {
    font-size: 16px;
  }
}

.faq-tooltip-over {
  position: absolute;
}

.faq-tooltip-gray {
  .tooltip-container {
    .ico {
      color: #c0bebe;

      fa-icon {
        color: #c0bebe;
      }
    }
  }
}

.faq-tooltip-brand {
  .tooltip-container {
    .ico {
      color: $color-brand-light;

      fa-icon {
        color: $color-brand-light;
      }
    }
  }
}

app-multiselect-filter {
  font-size: 14px;
}

.hidden {
  display: none;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cdk-overlay-container {
  z-index: 2000;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  display: inline-flex;

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.search-text {
  position: relative;
}

.search-text-list {
  text-align: left;
  position: absolute;
  top: 25px;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  border: 1px solid #f3f3f3;
  z-index: 1;
  font-size: 12px;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  display: none;

  &.showed {
    display: block;
  }
}

.search-text-list-item {
  padding: 2px 5px;

  &.empty {
    &:hover {
      cursor: default;
      background: transparent;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
}

.confirm-modal {
  text-align: center;

  .modal-dialog {
    max-width: 340px;
  }
}

.invisible {
  opacity: 0;
}

.pdf-view {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100%;

    .modal-content {
      height: 100%;

      .modal-body {
        embed {
          width: 100%;
          height: calc(100vh - 75px);
        }
      }
    }
  }
}

.simple-notification-wrapper.top {
  top: 80px !important;
  left: 0;
}

ngb-tooltip-window {
  div.tooltip-inner {
    background-color: white;
    color: black;
    border-radius: 0;
    border: 1px solid #c1c1c1;
    padding: 2px 5px;
    max-width: 300px;
    text-align: left;
  }

  &.bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $white;
    top: -1px;
    z-index: 1;
  }

  &.bs-tooltip-top .arrow::after,
  .bs-tooltip-auto[x-placement^='top'] .arrow::after {
    border-top-color: #c1c1c1;
    position: absolute;
    content: '';
    border-style: solid;
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    color: transparent;
  }

  &.bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $white;
    left: -1.5px;
    z-index: 1;
  }

  &.bs-tooltip-left .arrow::after,
  .bs-tooltip-auto[x-placement^='left'] .arrow::after {
    border-left-color: #c1c1c1;
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 0.4rem;
    color: transparent;
  }

  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.user-info-status {
  margin-right: 10px;
  > div {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;

    &.active {
      background-color: $color-mantis;
    }

    &.blocked,
    &.browsing {
      background-color: $color-lemon;
    }

    &.in_archive {
      background-color: $color-red-orange;
    }
  }
}
