// Описание таблиц

@import 'parts/color';

table {
  th {
    padding: 2px 4px;
    font-size: 14px;
  }

  td {
    padding: 2px 4px;
  }
  &.sticky-table {
    th {
      position: sticky;
      top: 0;
    }
    tbody {
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
      display: block;
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
    tr {
      table-layout: fixed;
      display: table;
      width: 100%;
    }
  }
}

.sticky-table-rich {
  table {
    position: relative;
  }
  th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
}

.base-table-container {
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
}

.base-table {
  width: 100%;

  th {
    padding: 4px 10px;
    vertical-align: top;
    background: $white;
    z-index: 10;
  }

  td {
    padding: 4px 10px;
    overflow: hidden;
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-white-smoke;
    }
  }
}

.thead-operations {
  display: flex;
  align-items: center;

  &__icon {
    padding: 4px;
    margin-right: 6px;
    color: $color-brand-light;
    transition: ease 0.3s;
    cursor: pointer;

    &--close {
      padding: 0;
    }

    &.active {
      border-radius: 3px;
      background-color: $color-brand-light;
      color: $white;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.col-header-sticky {
  position: sticky !important;
  z-index: 102 !important;
  background-color: $white;
}

.col-sticky {
  position: sticky !important;
  z-index: 101;
  background-color: $white;
}

.scroll-x-hidden {
  overflow-x: hidden;
}

.scroll-auto {
  overflow: auto;
}

.table-header-button {
  position: relative;
  column-gap: 4px;

  .table-header-button-close {
    position: absolute;
    top: -1px;
    left: -7px;
    z-index: 1000;
  }

  .tooltip-button-close {
    display: none;
    border: 1px solid $gray-light;
  }
}

.table-header-button-close:hover ~ .tooltip-button-close {
  display: block;
  position: absolute;
  top: 0;
  left: 16px;
  background-color: $white;
  font-size: 12px;
  line-height: 12px;
  z-index: 105;
  width: max-content;
  height: 24px;
  padding: 5px;
  font-weight: 400;
}
