.dc-competence-tree-item {
  font-size: 16px;
  margin-bottom: 6px;
  padding: 0 6px;
  width: 100%;

  &.selected {
    background: rgba($color-brand-light, 0.3);
  }

  &-heading {
    display: flex;
    align-items: center;
    width: 70%;

    app-svg-icon {
      margin-right: 6px;
    }
  }

  &-status {
    cursor: pointer;
    margin-right: 32px;
  }

  &-buttons {
    display: flex;
    align-items: center;
    color: $color-brand-light;

    app-svg-icon {
      cursor: pointer;
    }
  }

  &-add {
    margin-right: 12px;
  }
}

.dc-competence-tree-item-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.tree-node-level-2,
.tree-node-level-3,
.tree-node-level-4,
.tree-node-level-5,
.tree-node-level-6 {
  padding-left: 18px;
}

.kt-dual-list-without-level-padding {
  .tree-node-level-2,
  .tree-node-level-3,
  .tree-node-level-4,
  .tree-node-level-5,
  .tree-node-level-6 {
    padding-left: 0;
  }
}

.base-tree {
  .tree-node-level-2 {
    padding-left: 18px;
  }
}

.base-tree-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 6px;
  height: 22px;
  color: $black;

  app-svg-icon {
    margin-right: 7px;
    cursor: pointer;
  }

  &.selected {
    background: rgba($color-brand-light, 0.3);
  }
}

.dc-competence-tree-item-d-arrow {
  color: $color-brand-light;
  cursor: pointer;
  margin-right: 8px;
}

.dc-tree--my-competencies {
  overflow-y: auto;
  display: block;
}
