@for $i from 0 through 40 {
  .margin-#{$i*2} {
    margin: #{$i * 2}px !important;
  }

  .margin-t-#{$i*2} {
    margin-top: #{$i * 2}px !important;
  }

  .margin-b-#{$i*2} {
    margin-bottom: #{$i * 2}px !important;
  }

  .margin-tb-#{$i*2} {
    margin-top: #{$i * 2}px;
    margin-bottom: #{$i * 2}px !important;
  }

  .margin-l-#{$i*2} {
    margin-left: #{$i * 2}px !important;
  }

  .margin-r-#{$i*2} {
    margin-right: #{$i * 2}px !important;
  }

  .margin-lr-#{$i*2} {
    margin-left: #{$i * 2}px !important;
    margin-right: #{$i * 2}px !important;
  }
}
