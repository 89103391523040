@import 'assets/scss/variables';

.trade-card {
  height: 100%;
  font-size: 14px;
  padding: 20px 20px 30px 20px;
  min-width: 1200px;
  color: $color-dark-charcoal;

  .header1 {
    display: flex;
    align-items: center;
    column-gap: 10px;
    line-height: 1;
    margin-bottom: 15px;

    app-svg-icon {
      display: inline-block;
      color: $color-brand-light;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    .tooltip-container {
      padding: 0;
    }
  }
}

.supplier-trade-card {
  .grid-menu {
    grid-template-columns: minmax(550px, 40%) 20% 23%;
  }
}

.grid-menu {
  .row:last-child {
    margin-top: 30px;
  }

  &--edit {
    display: flex;
    flex-direction: column;
    height: calc(100% - 51px);

    .row:last-child {
      margin-bottom: 0;
      margin-top: auto;
    }
  }

  &.toggled {
    height: 0;
    overflow: hidden;
  }
}

.kt-id {
  margin-bottom: 10px;
}

.kt-name {
  user-select: none;

  &-field--readonly {
    display: flex;
    column-gap: 5px;
  }
}

.kt-logo {
  border-radius: 5px;
  border: 1px solid transparent;
  width: 120px;
  height: 80px;
  position: relative;
  cursor: pointer;

  &--without-image {
    border: 1px dashed var(---, $kt-gray);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: default;
  }

  &.readonly {
    pointer-events: none;
    cursor: default;
  }
}

.kt-logo-image-preview {
  position: relative;
  max-width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-brand-light;

  .clear {
    position: absolute;
    display: block;
    top: 0;
    right: -5px;
    transform: translateX(100%);
    cursor: pointer;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.kt-logo-help {
  width: 190px;
  margin-top: 10px;
  font-size: 12px;
  color: $color-dark-charcoal;
}

.kt-logo-text {
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $kt-gray;
  font-weight: 500;
  transition: 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}

.kt-upload-img {
  color: $kt-gray;
}

.kt-buttons {
  grid-column-start: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

// tabs

.trade-card-tabs {
  padding-top: 30px;
}

.kt-instrument {
  display: grid;
  grid-gap: 5px;
}

.kt-instrument a {
  color: $brand;
  margin-right: 10px;
  cursor: pointer;
}

app-trades-status-line {
  display: block;
  margin-bottom: 5px;
}

button {
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.require {
  color: $color-red-orange;
}

.timezone {
  font-size: 12px;
  height: fit-content;
  align-self: flex-end;
  padding-bottom: 12px;
}

.competencies {
  position: relative;
  height: 100%;

  a.trade-cell-arrow {
    cursor: pointer;
    transform: translate(-50%, 5px);
    color: $color-brand-light;
  }
}

.supplier-reject-modal .modal-dialog {
  max-width: 600px;
}
